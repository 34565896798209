const __request = require(`./__request`);
/**
 * 生成订单
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    // pParameter.urlSuffix = "/hotel/manager/order/createOrder"

    pParameter.urlSuffix = "/hotel/baseOrder/createOrder"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
