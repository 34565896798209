const __request = require(`./__request/`);
/**
 * 预定页面酒店日历
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    // pParameter.urlSuffix = "/hotel/manager/base/getHotelNightlyRatesPlan"

    pParameter.urlSuffix = "/hotel/common/getRatePlan"

    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
