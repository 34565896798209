const __request = require(`./__request`);
/**
 * 根据城市名称查询城市实体对象 http://hoteltest.yinzhilv.com/doc.html#/%E9%85%92%E5%BA%97%E6%A8%A1%E5%9D%97/new-hotel-controller/getTjielvBdCityUsingPOST
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"

    pParameter.urlSuffix = "/hotel/common/buyerBase/getCity"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
