const __request = require(`./__request/`);
/**
 * 酒店品牌
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    // pParameter.urlSuffix = "/hotel/manager/base/queryHotelBrandList"

    pParameter.urlSuffix = "/hotel/common/queryHotelBrandList"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
