import moment from "moment";
// 通过城市名字获取城市实体对象
import hotel_common_buyerBase_getCity from "@/lib/data-service/hotel/hotel_common_buyerBase_getCity";

// 国内酒店城市组件
import HotelDomesticCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/hotel-domestic-city-selector/1.0.0/index.vue";
// 国外酒店城市组件
import HotelForeignCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/hotel-foreign-city-selector/1.0.0/index.vue";

export default {
  components: {
    HotelDomesticCitySelector,
    HotelForeignCitySelector,
  },
  props: {
    activeName: {
      type: String,
      default: "",
    },
    siteList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      hotelInlandCity: null,
      hotelForeignCity: null,
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //
        },
      },
      pickerOption: this.setTime(),
      formData: {
        checkInTime: null,
        checkOutTime: null,
      }, //表单
      cityList: [
        {
          value: "30000898",
          label: "香港",
        },
        {
          value: "70139",
          label: "澳门",
        },
        {
          value: "30001017",
          label: "台湾",
        },
      ],
      options: [
        {
          value: null,
          label: "不限",
        },
        {
          value: 2,
          label: "经济型",
        },
        {
          value: 3,
          label: "舒适型",
        },
        {
          value: 4,
          label: "高档型",
        },
        {
          value: 5,
          label: "豪华型",
        },
      ],
      HongkongName: [], //港台列表信息
    };
  },
  mounted() {},
  watch: {
    hotelInlandCity(val) {
      const formData = this.formData;
      if (val) {
        formData.cityName = val.cityCn;
        formData.cityId = val.cityId;
      }
    },
    hotelForeignCity(val) {
      const formData = this.formData;
      if (val) {
        formData.cityName = val.cityCn;
        formData.cityId = val.cityId;
      }
    },
    activeName(val) {
      if (val) {
        const formData = this.formData;
        formData.cityId = "";
        formData.cityName = "";
      }
    },
  },
  methods: {
    init() {
      const formData = this.formData;
      if (this.activeName == 1) {
        if (formData.cityName) {
          hotel_common_buyerBase_getCity({ cityName: formData.cityName })
            .then((result) => {
              this.hotelInlandCity = {
                cityId: result.data.cityId.toString(),//中山
            };
            })
            .catch((err) => {});
        }
      }
    },
    submitForm() {
      //1:国内,2:港澳台,3:国际)ountryType
      let data = this.formData;

      if (!data.checkInTime && !data.checkOutTime) {
        data.checkInTime = moment().format("YYYY-MM-DD");
        data.checkOutTime = moment()
          .add(1, "days")
          .format("YYYY-MM-DD");
      }

      if (data.checkInTime && !data.checkOutTime) {
        data.checkOutTime = moment(data.checkInTime)
          .add(1, "days")
          .format("YYYY-MM-DD");
      }

      if (!data.checkInTime && data.checkOutTime) {
        data.checkInTime = moment(data.checkOutTime)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }
      data.countryType = this.activeName;
      if (Number(data.countryType) === 2) {
        data.cityId = this.HongkongName.value;
        data.cityName = this.HongkongName.label;
      }

      if (!data.cityName) {
        return this.$message({
          type: "warning",
          message: "请选择目的地",
        });
      }

      this.$router.push({
        name: "distributor-hotel-front-search",
        query: {
          data: JSON.stringify(this.formData),
        },
      });
    },
    changeInTime() {
      if (!this.formData.checkInTime) return;
      this.formData.checkOutTime = moment(this.formData.checkInTime)
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.$refs.outTime.focus();
    },
    change_checkOutTime() {
      // if (this.formData.checkInTime) return;
      // this.formData.checkInTime = moment(this.formData.checkOutTime).add(-1, 'days').format('YYYY-MM-DD');
      // this.$refs.starTime.focus();
    },
    setTime() {
      let _this = this;
      return {
        disabledDate: (time) => {
          if (_this.formData.checkInTime) {
            return (
              time.getTime() < new Date(_this.formData.checkInTime).valueOf()
            );
          } else {
            return time.getTime() < Date.now();
          }
        },
      };
    },
  },
};
