const __request = require(`./__request/`);
/**
 * 酒店订单详情列表
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    // pParameter.urlSuffix = "/hotel/manager/order/queryHotelOrderList"

    pParameter.urlSuffix = "/hotel/baseOrder/queryHotelOrderList"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
