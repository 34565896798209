const __request = require(`../default/__request/`);
/**
 * 易宝支付
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/hotel/payment/yeepay"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
