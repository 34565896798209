import store from '@/lib/store';

const get_local_city = function() {
  let localCity = JSON.parse(sessionStorage.getItem("localCity"));
  if (localCity && localCity.cityName) {
    return localCity;
  } else {
    localCity = store.state.localCity.cityName;
    if (localCity.cityName) {
      return localCity;
    } else {
      return false;
    }
  }
};

export default get_local_city;
