const __request = require(`./__request/`);
/**
 * 推荐酒店
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    // pParameter.urlSuffix = "/hotel/manager/base/queryRecommendHotelList"
    // pParameter.urlSuffix = "/hotel/buyerBase/queryRecommendHotelList"
    pParameter.urlSuffix = "/hotel/common/buyerBase/getRecommendHotelList"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
