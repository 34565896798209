const __request = require(`./__request/`);
/**
 * 酒店订单详情取消列表
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    // pParameter.urlSuffix = "/hotel/manager/order/queryHotelCancelOrderList"

    pParameter.urlSuffix = "/hotel/baseOrder/queryHotelCancelOrderList"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
