// web_common_TourismMall_getArea
const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 根据IP获取所在城市
// 接口文档地址：http://219.128.52.2:9999/doc.html#/yinzhilv_travel/%E5%95%86%E5%9F%8E%E9%A6%96%E9%A1%B5/getAreaByIPUsingPOST
const web_common_TourismMall_getArea = (pParameter) => {

    if (!pParameter) pParameter = {};

    const p = {
        method: 'post',
        urlSuffix: '/web/common/TourismMall/getArea',
        data: pParameter,
        check_token: true,
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;

};
module.exports = web_common_TourismMall_getArea;