// 选择表单
import SeekFrom from "@/page/distributor/hotel/components/hotel-seek/1.0.1/index.vue";

// 获取定位城市
import get_local_city from "@/lib/common-service/get_local_city/index.js";
// 通过ip获取定位城市
import web_common_TourismMall_getArea from "@/lib/data-service/default/web_common_TourismMall_getArea";

import {
  // 获取城市
  getCityList,
  // 推荐酒店
  recommendHotel,
  // 酒店房型最低价
  getHotelMinPriceByHotelId,
} from "@/lib/data-service/hotel";
import { STATUS } from "@/lib/deprecated/utils/status";
import { setSession } from "@/lib/deprecated/utils/caching";
const SUCCESS_CODE = STATUS.success;
export default {
  name: "hotel",
  data() {
    return {
      activeName: "1", //点击切换
      foreignSiteList: [], //国外地址
      inlandSiteList: [], //国内地址
      recommendHotelList: [], //推荐酒店
      siteList: [],
      headSeekCity: "",
    };
  },
  mounted() {
    this.headSeekCity = this.$store.state.selectCity.city;

    const localCity = get_local_city();
    this.$nextTick(() => {
      if (localCity.cityName) {
        this.$refs.inlandForm.formData.cityName = localCity.cityName;
        this.$refs.inlandForm.init();
        this._recommendHotelList(localCity.cityName.replace('市', ''));
      } else {
        web_common_TourismMall_getArea()
          .then((res) => {
            this.$refs.inlandForm.formData.cityName = res.cityName;
            this.$refs.inlandForm.init();
            this._recommendHotelList(res.cityName.replace('市', ''));
          })
          .catch(() => {});
      }
    });
    this._getCityList(this.activeName);
  },
  watch: {
    activeName(val) {
      if (val == 2) return;
      this._getCityList(val);
    },
  },
  methods: {
    beforeLeave(val) {
      console.log(val);
      // 港澳台和国际禁用
      return val === 1
    },
    seeMore() {
      this.$router.push({
        name: "hotelSearch",
        params: { cityList: this.siteList },
      });
    },
    //酒店推荐
    _recommendHotelList(val) {
      recommendHotel({ currentPage: 1, pageSize: 4, cityName: val }).then(
        (res) => {
          if (res.pageResult.pageData.length > 0) {
            this.recommendHotelList = res.pageResult.pageData;
            let ids = this.recommendHotelList.map((item) => {
              return item.hotelId;
            });
            this._getHotelMinPriceByHotelId(ids);
          } else {
            this.$store.commit("SET_SELECTCITY", "广州");
          }
        }
      );
    },
    //酒店推荐点击
    getRecommendItem(val) {
      let data = {
        checkInTime: this.$moment().format("YYYY-MM-DD"),
        checkOutTime: this.$moment()
          .add(1, "days")
          .format("YYYY-MM-DD"),
        hotelId: val.hotelId,
      };
      setSession("HOTELINFO", val);
      setSession("HOTELFORMDATA", data);
      this.$router.push({
        name: "distributor-hotel-front-hotel-details",
      });
    },
    async _getHotelMinPriceByHotelId(ids) {
      let data = {
        checkInDate: this.$moment().format("YYYY-MM-DD"),
        checkOutDate: this.$moment()
          .add(1, "days")
          .format("YYYY-MM-DD"),
        hotelIds: ids,
      };
      try {
        let res = await getHotelMinPriceByHotelId(data);
        if (res.minPriceResults.length > 0) {
          this.recommendHotelList.map((item, index) => {
            res.minPriceResults.map((sItem) => {
              item.hotelId == sItem.hotelId
                ? (item.minPrice = sItem.minPrice)
                : "";
            });
          });
          this.$forceUpdate();
        }
      } catch (error) {}
    },
    //获取城市列表
    _getCityList(val) {
      if (val == 1) {
        if (this.inlandSiteList.length != 0) {
          this.siteList = this.inlandSiteList;
          return;
        }
      } else if (val == 3) {
        if (this.foreignSiteList.length != 0) {
          this.siteList = this.foreignSiteList;
          return;
        }
      }
      getCityList({ countryType: val }).then((res) => {
        if (res.code === SUCCESS_CODE) {
          this.siteList = res.citys;
          val == 1
            ? (this.inlandSiteList = res.citys)
            : val == 3
            ? (this.foreignSiteList = res.citys)
            : "";
        }
      });
    },
  },
  components: { SeekFrom },
};
