const __request = require(`./__request`);
/**
 * 获取酒店最低报价
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    // pParameter.urlSuffix = "/hotel/manager/base/getHotelMinPriceByHotelId"

    pParameter.urlSuffix = "/hotel/common/getHotelMinPrice"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
