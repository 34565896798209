const __request = require(`../default/__request/`);
/**
 * 预定页面酒店信息
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/hotel/manager/base/getReserveHotelInfo"

    // pParameter.urlSuffix = "/hotel/common/buyerBase/getRoomPackageInfo"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
