const __request = require(`../default/__request/`);
/**
 * 酒店订单须知
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/hotel/manager/base/queryTermsList"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
