const __request = require(`../default/__request/`);
/**
 * 酒店房型
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/hotel/manager/base/queryRoomRatePlan"

    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
