const __request = require(`./__request/`);
/**
 * 酒店关注
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    // pParameter.urlSuffix = "/hotel/manager/base/queryFocusHotelList"

    pParameter.urlSuffix = "/hotel/buyerBase/queryFocusHotelList"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
