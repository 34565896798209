const __request = require(`./__request/`);
/**
 * 酒店房型报价
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    // pParameter.urlSuffix = "/hotel/manager/base/queryRoomPackageInfo"

    pParameter.urlSuffix = "/hotel/common/buyerBase/getRoomPackageInfo"

    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
